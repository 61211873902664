.success-toast {
  background-color: #047a43 !important;
  position: fixed;
  right: calc(50% - 250px);
  top: 30px;
  z-index: 100;
  min-width: 500px;
  color: white;
  font-size: 14px !important;
  border-radius: 3px;
  button {
    background-color: transparent !important;
    border: none;
    align-items: center;
  }
}
