.upload-avatar {
    text-align: center;

    canvas {
        height: 100% !important;
        width: 100% !important;
    }

    &__scale-input {
        margin-top: 16px;
        width: 200px;
    }

    &__footer {
        align-items: center;
        display: flex;
        margin-top: 16px;
    }

    &__input {
        width: 50%;

        input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        label {
            cursor: pointer;
            margin-bottom: 0;
        }
    }

    &__action {
        text-align: right;
        width: 50%;

        .btn-cancel {
            background-color: transparent;
            border: none;
            color: black;
            outline: none;
            width: 100px;
        }

        .btn-submit {
            width: 100px;
        }
    }
}
