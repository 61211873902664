.employee-form-body {
  width: 100vw;
  margin-top: 135px;
  .employee-form-main {
    max-width: 1000px;
    width: 75%;
    margin: 24px auto;
    padding: 16px 48px 48px;
    background: #f5f5f5;

    .title {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 20px;
    }

    .title-main__form {
      font-size: 20px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.85);
    }

    .title-child__form {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
    }

    .row {
      margin-left: 0;
      margin-right: 0;
    }
    .col {
      padding-left: 0;
      padding-right: 0;
    }
    .pa-l-10 {
      padding-left: 10%;
    }
  }
}

.freememo {
  width: 100%;
  padding: 0 10px;
  font-size: 16px;

  outline: none;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  margin-left: 0px;

  &::placeholder {
    color: #aaa;
  }

  &:focus {
    border: 1px solid rgba(255, 176, 22, 0.6);
  }
}

.group-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  button {
    width: 184px;
    background: #f5da6e !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
      0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: none;

    &:hover {
      opacity: 0.8;
    }
  }
}

.input-phone__two {
  position: relative;

  &:after,
  &:before {
    content: '-';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    left: -13px;
  }

  &:after {
    right: 7px;
  }
}

.text-hint {
  font-size: 12px;
  line-height: 22px;
  color: #616161;
}

.line-spread {
  margin: 24px 0;
  height: 1px;
  width: 100%;
  background: #d9d9d9;
}

.width-98 {
  width: 98px !important;
}

.form-border {
  padding: 8px 0px 0px 0px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
}

.add-item__form {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  color: #296661;
  justify-content: flex-end;
  margin-top: 8px;

  img {
    margin-right: 5px;
  }
}

.text-right {
  text-align: right;
}

.required-star {
  color: #ff4d4f;
}

.input-number__phone {
  width: 50px !important;
}

// select custom
select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
  padding-left: 0.5em;
  padding-right: 1.5em;
}

.is-invalid {
  select {
    background-image: none;
    border-color: #dd5e56;
  }
}

.select-common {
  border-radius: 2px;
  border: 1px solid silver;

  &:invalid {
    color: grey;
  }

  &:focus-within {
    outline: none;
  }
}

// radio custom
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #296661;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
