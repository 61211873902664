// Main screen
.grow {
    flex-grow: 1;
  }
  
  .ledger-info-card {
    display: flex;
    flex-direction: column;
    margin-right: 5rem;
    width: 50rem;
  
    .title {
      display: flex;
      flex-direction: row;
      justify-content: center;
  
      .edit-button {
        display: flex;
        align-items: center;
        background-color: transparent !important;
        border-width: 0 !important;
        color: royalblue !important;
        margin-bottom: 0 !important;
  
        .button-text {
          margin-left: 0.5rem;
          color: darkgreen;
          font-weight: bold;
        }
      }
    }
  
    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 1rem;
      border: 2px solid rgba(128, 128, 128, 0.5);
      border-radius: 0.5rem;
      background-color: #fff9d9;
      height: 10rem;
  
      .sub-info {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 1rem;
  
        .file {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: white;
          border-radius: 0.5rem;
          margin-right: 1rem;
          width: 70px;
          height: 70px;
  
          .label {
            font-size: 1.2rem;
          }
        }
        .data-info {
          display: flex;
          flex-direction: column;
          .data-info-row {
            display: flex;
            flex-direction: row;
            .data-info-label {
              display: flex;
              flex-direction: column;
              font-weight: bold;
              color: #555555;
            }
            .data-info-label-n {
              display: flex;
              flex-direction: column;
              color: gray;
            }
            .edit-day {
              display: flex;
              flex-direction: column;
              color: darkgreen;
              font-weight: bold;
            }
          }
        }
        .ledger-file-info {
          display: flex;
          flex-direction: row;
          .data-info-label {
            display: flex;
            flex-direction: column;
            font-weight: bold;
            color: #555555;
          }
          .edit-day {
            display: flex;
            flex-direction: column;
            color: darkgreen;
            font-weight: bold;
          }
        }
        .edit-button {
          display: flex;
          align-items: center;
          background-color: transparent !important;
          border-width: 0 !important;
          color: royalblue !important;
          margin-bottom: 0 !important;
  
          .button-text {
            margin-left: 0.5rem;
            color: darkgreen;
            font-weight: bold;
          }
        }
      }
    }
  }
  