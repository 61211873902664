.custom-table,
.sticky-table {
  width: 100%;
  margin-top: 16px;
  line-height: 40px;
  color: $text-onlight;
  thead {
    top: 105px;
    border-bottom: 1px solid #bdbdbd;
    th {
      font-weight: normal;
    }
  }
  tbody tr {
    cursor: pointer;
    td:first-child,
    td:last-child {
      color: $primary-dark;
    }
  }
}

.sticky-table {
  overflow-x: scroll;
  .cell-pd {
    padding: 0 8px;
  }
  thead th:first-child {
    background-color: white;
    border-right: 1px solid #bdbdbd;
    text-align: center;
    font-weight: bold;
  }
  tbody tr {
    line-height: 48px;
    td:first-child {
      background-color: white;
      border-right: 1px solid #bdbdbd;
    }
  }
}

.pagin-container {
  justify-content: center;
  margin-top: 20px;
  button {
    background-color: white;
    width: 40px;
    height: 40px;
    border: none;
    font-size: 18px;
    margin: 0 29px;
    color: #4f4f4f;
  }
  button:disabled {
    color: #bbbbbb;
    border-radius: 50%;
  }
  button.active {
    color: white;
    border-radius: 50%;
    background-color: $primary-dark;
  }
}
.other-search-bar {
  display: flex;
  align-items: center;
  width: 300px !important;
  .search-btn {
    width: 70px;
    border: 0.5px solid #bbbbbb;
    font-size: 1.5rem;
    border-radius: 3px;
  }
  .input-keyword{
    font-size: 1.5rem;
  }
}
.no-data-txt,
.no-data-txt-sm,
.no-data-txt-xs {
  text-align: center;
  width: 100vw;
  font-size: 24px;
  font-weight: bold;
  margin: 15vh 0;
}
.no-data-txt-sm {
  margin: 8vh 0;
}
.no-data-txt-xs {
  margin: 2vh 0;
}
.aln-right{
  text-align: right;
}

thead, tbody{
  display: block;
}

thead {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: white;
  z-index: 1;
}

tbody tr td:first-child{
  position: sticky;
  position: -webkit-sticky;
  z-index: 0;
  left: 0;
}

thead tr th:first-child{
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  top: 0;
}