.btn-register {
    background-color: #F5DA6E;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    margin-top: 16px;
    width: 15%;
}

.btn-update {
    background-color: #F5DA6E;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    margin-top: 16px;
    width: 15%;
}
