// Main screen
.grow {
  flex-grow: 1;
}

.list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .edit {
    display: flex;
    flex-direction: column;
    margin-right: 5rem;

    .title {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .edit-button {
        display: flex;
        align-items: center;
        background-color: transparent !important;
        border-width: 0 !important;
        color: royalblue !important;
        margin-bottom: 0 !important;

        .button-text {
          margin-left: 0.5rem;
          color: darkgreen;
          font-weight: bold;
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 1rem;
      border: 2px solid rgba(128, 128, 128, 0.5);
      border-radius: 0.5rem;
      background-color: #fff9d9;
      height: 10rem;
      width: 40rem;

      .sub-info {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 1rem;

        .file {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: white;
          border-radius: 0.5rem;
          margin-right: 1rem;
          width: 70px;
          height: 70px;

          .label {
            font-size: 1.2rem;
          }
        }

        .edit-day {
          color: darkgreen;
          font-weight: bold;
        }


        .edit-button {
          display: flex;
          align-items: center;
          background-color: transparent !important;
          border-width: 0 !important;
          color: royalblue !important;
          margin-bottom: 0 !important;

          .button-text {
            margin-left: 0.5rem;
            color: darkgreen;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.history {
  display: flex;
  flex-direction: column;
  background-color: rgba(156, 155, 155, 0.274);
  width: 100%;
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 0.5rem;

  .title {
    font-size: 1.5rem;
    color: gray;
  }

  .split {
    margin-top: 1rem;
    width: 100%;
    height: 0.5rem;
    border-top: 2px solid rgba(128, 128, 128, 0.5);
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    height: 8rem;
    width: 100%;

    .sub-info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 1rem;

      .file {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 0.5rem;
        margin-right: 1rem;
        width: 70px;
        height: 70px;

        .label {
          font-size: 1.2rem;
        }
      }

      .edit-day {
        color: darkgreen;
        font-weight: bold;
      }
    }
  }
}

.content:after {
  content: '';
  display: block;
  height: 4rem;
}

// Detail screen
.detail-title {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.asterisk {
  color: red;
}

.detail-content {
  display: flex;
  flex-direction: column;
  background-color: rgba(156, 155, 155, 0.274);
  width: 100%;
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 0.5rem;

  .notice {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.3rem;
  }

  .form {
    display: flex;
    flex-direction: row;
    font-size: 1.8rem;

    .title-list {
      display: flex;
      flex-direction: column;

      .element {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 7rem;
      }
    }

    .select-list {
      margin-left: 2rem;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .element {
        display: flex;
        align-items: center;
        height: 7rem;

        .form-year {
          width: 20%;
          font-size: 1.6rem;
        }

        .form-datemonth {
          width: 5%;
          font-size: 1.6rem;
        }
      }
    }
  }

  .submit {
    margin-bottom: 2rem;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    &__button{
      width: 15rem;
      background-color: #fff9d9;
      color: black;
      border-width: 0px;
    }
  }
}

// Image upload screen

.upload-content {
  display: flex;
  flex-direction: column;
  background-color: #F5F5F5;
  max-width: 65%;
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: auto;
  .upload-group {
    flex-direction: row;
    padding-top: 10px;

    .title{
      margin-left: 2rem;
      font-size: 1.4rem;
    }

    .upload{
      flex-grow: 1;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .submit {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    &__button{
      width: 15rem;
      background-color: #fff9d9;
      color: black;
      border-width: 0px;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}