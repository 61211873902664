/* Make the customizations */
$theme-colors: (
  'primary': #4ea391,
  'secondary': #f5da6e,
  'danger': #dd5e56,
  'light': #ffffff,
);

$font-weight-normal: 400;
$font-weight-bold: 700;

.spinner-border {
  margin-top: 100px;
  width: 80px !important;
  height: 80px !important;
}
$font-size-base: 1.4rem;
$line-height-base: 2.2;
// $font-size-medium: 1.4rem;

// https://bootstrap-vue.org/docs/reference/spacing-classes
// This for exp makes `pr-3` mean `padding-right: 0.8rem` and `pr-2` mean `padding-right: 0.4rem`
$spacer: 0.8rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    // 0.2rem
    2:
      (
        $spacer * 0.5,
      ),
    // 0.4rem
    3: $spacer,
    // 0.8rem
    4:
      (
        $spacer * 1.5,
      ),
    // 1.2rem
    5:
      (
        $spacer * 2,
      ),
    // 1.6rem
    6:
      (
        $spacer * 2.5,
      ),
    // 2.0rem
    7:
      (
        $spacer * 3,
      ),
    // 2.4rem
    8:
      (
        $spacer * 3.5,
      ),
    // 2.8rem
    9:
      (
        $spacer * 4,
      ),
    // 3.2rem
    10:
      (
        $spacer * 4.5,
      ),
    // 3.6rem
    11:
      (
        $spacer * 5,
      )
      // 4.0rem,,,,,,,,,
  ),
  $spacers
);

/* import bootstrap to set changes */
@import '../../../node_modules/bootstrap/scss/bootstrap';
