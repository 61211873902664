// base
@import './base/base';
@import './base/custom.scss';
@import './base/typography.scss';
@import './base/table.scss';
@import './base/modal.scss';
@import './base/layout.scss';
@import './base/button.scss';

// components
@import './components/ledgerInfoCard.scss';

// views
@import './views/footer.scss';
@import './views/navbar.scss';
@import './views/disability.scss';
@import './views/login.scss';
@import './views/employeeForm.scss';
@import './views/employeeDetailInfo.scss';
@import './views/employeeList.scss';
@import './views/driverLicenseDetail.scss';
@import './views/familyEdit.scss';
@import './views/relatedDocsImages/listImage.scss';
@import './views/relatedDocsImages/selectImage.scss';