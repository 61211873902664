@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.logo {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: rgb(49, 182, 137) !important;
  font-size: 3rem !important;
  cursor: pointer;
  height: 4rem;
  vertical-align: top;
}

.avatar {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 1rem;
}

.user {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 1rem;
}

.user-info {
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .name {
    padding-bottom: 0.25rem;
    font-weight: bold;
    font-size: 1.7rem;
    color: rgb(4, 122, 67);
    line-height: 0.75em;
    padding-top: 0.4em;
  }

  .department {
    padding-top: 0.25rem;
    font-size: 1.2rem;
  }
}

.breadcrumb {
  background-color: transparent !important;
  align-items: center;
  padding-left: 0 !important;
  font-size: 1.4rem;
  padding: 0;
  margin-bottom: 0;
  li{
    margin-top: 1px;
  }
}

.back-button {
  background-color: transparent !important;
  border: none;
  border-width: 0 !important;
  color:#4ea391 !important;
  margin-bottom: 0 !important;
  margin-right: 2rem;
  font-size: 1.6rem;
  @extend .flat-button;
  svg {
    vertical-align: text-bottom;
  }
}

.flat-button{
  outline: none !important;
  box-shadow: none !important;
}
.title {
  margin-left: 1rem;
}

.line{
  width: 100%;
  height: 0.5rem;
  border-bottom: 2px solid rgba(128, 128, 128, 0.5);
}

.logout-button{
  @extend .back-button;
  text-align: right;
  padding-left: 0;
  position: absolute;
  left: calc(100vw - 14rem);
}

.nav-container{
  margin-top: 0.5rem;
  width: 100%;
  nav{
    width: calc(100vw);
    position: sticky;
    position: -webkit-sticky;
    left: 0;
  }
}

.user{
  position: absolute;
  right: 0;
}

.app{
  height: calc(100vh - 20px);
}