.related-docs-images__select__wrapper {
    background-color: #F2F2F2;
    border-radius: 4px;
    margin-top: 16px;
    padding: 16px;

    > div:first-child {
        color: #212121;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.005em;
        line-height: 22px;
        text-align: left;
    }

    > label {
        background-color: white;
        border: 1px dashed #BDBDBD;
        border-radius: 2px;
        box-sizing: border-box;
        margin-top: 16px;
        padding: 16px;
        text-align: center;
        width: 100%;

        > input {
            display: none;
        }

        > div:nth-child(3) {
            color: #616161;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 22px;
            margin-top: 8px;
            text-align: center;
        }

        > div:last-child {
            color: #296661;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 0em;
            line-height: 22px;
            margin-top: 8px;
            text-align: center;
        }
    }
}
