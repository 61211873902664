$bg-color: #e2e4e5;
$primary-dark: #296661;
$secondary-light: #fff9d9;
$text-onlight: #616161;
$gray: #f5f5f5;
$gray2: #f2f2f2;

html {
  scroll-behavior: smooth;
}

.content {
  width: 75%;
  display: block;
  margin: 2rem auto;
  margin-top: 135px;
}
.content-sm {
  padding: 8px;
}
.app-mrg {
  margin-left: 24px;
  margin-right: 24px;
}
.has-shadow {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
}
.screen-center {
  position: absolute;
  top: 47%;
  left: 47%;
  transform: translate(-50%, -50%);
}

.w-5 {
  width: 5%;
}
.divider-btm {
  border-bottom: 1px solid #bdbdbd;
}
.divider-top {
  border-top: 1px solid #bdbdbd;
}
.w-20-px {
  width: 20px;
}
.w-30-i {
  width: 300px !important;
}
.w-100-i {
  width: 100px !important;
}
.w-form {
  width: 75vw;
  max-width: 1000px;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-px-80 {
  width: 80px;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}
.height-40 {
  height: 40px;
}
.height-120 {
  height: 120px;
}
// margin and padding
.pa-16 {
  padding: 16px;
}
.mr-r-md {
  margin-right: 10px;
}
.mr-r-bg {
  margin-right: 20px;
}
.mr-l-md {
  margin-left: 10px;
}
.mr-l-bg {
  margin-left: 20px;
}
.mr-t-sm {
  margin-top: 4px;
}
.mr-t-md {
  margin-top: 10px;
}
.mr-t-bg {
  margin-top: 30px;
}
.mr-b-md {
  margin-bottom: 10px;
}
.mr-b-xl {
  margin-bottom: 54px !important;
}
.dropzone-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
}
.item-center {
  align-items: center;
}
// position
.dis-flex {
  display: flex;
}
.space-btwn {
  justify-content: space-between;
}
.flex-end {
  justify-content: end;
}
.flex-center {
  justify-content: center;
}
.pos-rel {
  position: relative;
}
.pos-abs {
  position: absolute;
}
.abs-left {
  left: 0;
}
.wht-sp-pre {
  white-space: pre;
}
.bg-sec {
  background-color: $secondary-light;
}
.bg-gray2 {
  background-color: $gray2;
  border-radius: 0.5rem;
}
.bg-gray {
  background-color: $gray;
}
.border-slim {
  border: 1px solid #bdbdbd;
}
.border-none {
  border: none !important;
}
.border-rad-4 {
  border-radius: 4px;
}
// text style
.text-lght {
  color: $text-onlight;
}
.text-prim {
  color: $primary-dark !important;
}
.sz-16 {
  font-size: 16px;
}
.sz-12 {
  font-size: 12px;
}
.bold {
  font-weight: 700;
}
.text-cap {
  text-transform: capitalize;
}
