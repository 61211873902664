.filter-btn-group {
  margin: 10px 0 30px;
  display: flex;
  align-items: center;
  .filter-btn {
    height: 30px;
    font-size: 14px;
    display: inline;
    background: rgba(255, 255, 255, 0.5);
    border: 0.5px solid rgba(151, 151, 151, 0.96);
    box-sizing: border-box;
    color: $primary-dark;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding-top: 3px;
    padding-left: 5px;
    line-height: 1;
    font-weight: bold;
  }
  .info-choicechip {
    margin: 0 5px;
    text-align: center;
    padding: 0 15px;
    background: #F5F5F5;
    border-radius: 34px;
    min-width: 70px;
    span + span:last-child::before {
      content: "　と　";
    }
    span:nth-of-type(n + 2):not(:last-child)::before {
      content: "、";
    }
  }
}
.list-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.employee-list{
  width: max-content;
}

.employee-list > div{
  width: calc(100vw - 6rem);
  position: sticky;
  position: -webkit-sticky;
  left: 2rem;
}