.content:after {
  content: '';
  display: block;
  height: 4rem;
}

// Detail screen
.driver-license-title {
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-weight: bold;
}

.driver-license-content {
  display: flex;
  flex-direction: column;
  background-color: #F5F5F5;
  width: 100%;
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 0.5rem;
  
  .notice {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.3rem;
  }

  .form {
    // display: flex;
    // flex-direction: row;

    .driver-license-row {
      display: flex;
    }

    .title-list {
      display: flex;
      flex-direction: column;
      width: 15rem;

      .element {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 7rem;
      }
    }

    .select-list {
      margin-left: 2rem;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .element {
        display: flex;
        align-items: center;
        height: 7rem;

        .date-label {
          margin-left:2rem;
        }

        .form-year {
          width: 70%;
          font-size: 1.6rem;
        }

        .form-datemonth {
          width: 15%;
          font-size: 1.6rem;
        }

        .form-textarea {
          width: 40%;
          font-size: 1.6rem;
        }

        .form-checkbox {
          width: 10rem;
          input {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }

  .submit {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    &__button{
      width: 15rem;
      background-color: #fff9d9;
      color: black;
      border-width: 0px;
    }
  }
}

.driver-license{
  margin-left: auto;
  margin-right: auto;
}

.checkbox-list
{
  margin-left: 2rem;
  .row{
    display: block;
  }
}
.align-left {
  text-align: left;
}