.input-wrap {
  &.is-invalid {
    input {
      border-color: #dd5e56;
      &:focus {
        border-color: #dd5e56;
        box-shadow: 0 0 0 0.2rem rgba(221, 94, 86, 0.25);
      }
    }
  }
  .wrap-input__common {
    & > div {
      display: flex;
      align-items: center;

      label {
        text-align: right;
      }
    }
  }

  .lib-input-wrapper {
    position: relative;
  }

  label {
    font-weight: 500;
    font-size: 14px;
    display: block;
  }

  img {
    width: 20px;
    height: 20px;

    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    cursor: pointer;

    &:hover + {
      .error-msg {
        opacity: 1;
      }
    }
  }

  .error-msg {
    position: absolute;
    top: -25px;
    right: 0;
    text-align: right;
    line-height: 35px;
    height: 35px;
    padding: 0 6px;
    color: #ff4d4f;
    font-size: 0.8rem;
    width: max-content;
  }

  .error-icon svg {
    position: absolute;
    top: 12px;
    right: 10px;
  }

  .required-star {
    color: #ff4d4f;
  }

  input {
    height: 38px;
    width: 100%;
    min-width: 90px;
    padding: 0 10px;
    font-size: 16px;

    outline: none;
    background: #ffffff;
    border: 1px solid #bdbdbd;
    border-radius: 2px;
    // margin-left: 8px;

    &::placeholder {
      color: #aaa;
    }

    &:focus {
      border: 1px solid rgba(255, 176, 22, 0.6);
    }
  }

  select {
    height: 38px;
    &:focus {
      border: 1px solid rgba(255, 176, 22, 0.6);
    }
  }
}

.checkbox {
  display: block;
  cursor: pointer;
  position: relative;
  &.invalid {
    input {
      border-color: #dd5e56;
    }
    span {
      color: #dd5e56;
    }
  }
  &.disabled {
    input {
      border-color: #e9ecef;
    }
    span {
      opacity: 0.5;
    }
  }
}

.checkbox > span {
  padding: 0.25rem 0.25rem;
}

.checkbox > input {
  height: 16px;
  width: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #296661;
  border-radius: 3px;
  outline: none;
  transition-duration: 0.3s;
  background-color: white;
  cursor: pointer;
  vertical-align: middle;
}

.checkbox > input:checked {
  border: 1px solid gray;
  background-color: #296661;
}

.checkbox > input:checked + span::before {
  content: '';
  position: absolute;
  left: 5px;
  top: 10px;
  width: 7px;
  height: 11px;
  border: solid white;
  border-style: inset;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.checkbox > input:active {
  border: 2px solid #34495e;
}
