.footer {
  display: flex;
  flex-direction: row-reverse;
  background-color: #e4e4e4;
  height: 4rem;
  align-items: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 10;
  .select {
    font-size: 1.2rem;
    font-weight: bold;
    color: #4f817d;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
