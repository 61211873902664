// Main screen
.frame {
  /* Auto Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;

  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 180px;

  border-radius: 4px;
}

.detailInfo {
  /* detail */
  /* Auto Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;

  position: static;
  width: 100%;
  left: 24px;
  /* Background/white */
  background: #ffffff;

  /* Border/main */
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 4px;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 10px 0px;
}

.detailInfoHead {
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;

  position: static;
  width: 100%;
  height: 22px;
  left: 16px;
  top: 16px;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px 20px 0px;

  .detailInfoHeadTitle {
    font-size: 20px;
    width: 100;
  }

  .line {
    border: 1px solid #eeeeee;
  }

  .detailInfoData {
    width: 100%;
    left: 0px;
    top: 0px;

    /* Background/white */
    background: #ffffff;
    /* Border/main */
    border: 1px solid #bdbdbd;
  }
}

.personal-info {
  display: flex;
  justify-content: space-between;
  margin: 12px 0px 24px;
}
.dropdown-divider {
  height: 0;
  overflow: hidden;
  border-top: 1px solid #bdbdbd;
}
.static-breadcrumb {
  white-space: pre;
  display: flex;
  font-size: 14px;
  align-items: center;
}
.item-wrapper {
  padding: 16px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 16px 0;
  .item-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h5 {
      margin-bottom: 0 !important;
    }
  }
}

.item-btn-group,
.item-edit-file {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: $primary-dark;
  white-space: pre;
  user-select: none;
  span {
    width: 30px;
    color: #bdbdbd;
    text-align: center;
  }
}

.item-edit-file {
  position: absolute;
  right: 8px;
  top: 16px;
}
.item-edit-file-center {
  top: calc(50% - 16px);
}

.print-btn,
.new-emp-btn,
.save-btn,
.delete-btn {
  background-color: transparent !important;
  border: none;
  width: 120px;
  height: 40px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.none-btn {
  background: none;
  border: none;
  box-shadow: none;
}
.new-emp-btn,
.save-btn {
  background-color: #f5da6e !important;
  font-size: 14px;
  font-weight: bold;
  color: black;
  &:hover {
    opacity: 0.8;
    color: white;
  }
}
.delete-btn {
  // top: -80px;
  // right: 0;
  right: 16px;
  background-color: white !important;
  font-size: 14px;
  font-weight: bold;
  color: #dd5e56;
  align-content: center;
}
.delete-btn-high {
  // top: -120px;
}
.file-handler {
  padding: 16px 8px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

.file-info {
  width: 60px;
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $text-onlight;
}

.divider {
  border-top: 1px solid #eeeeee;
}
.employment {
  background-color: $gray;
  padding: 16px;
  width: 75vw;
  max-width: 1000px;
  margin-top: 135px;
}
.required::before {
  content: '* ';
  color: red;
}
.time-unit,
.text-unit,
.prefix-unit {
  background: transparent;
  top: 5px;
  right: 5px;
  width: 30px;
  color: $text-onlight;
  text-align: right;
}
.dropdown-unit {
  right: 15px;
}
.text-unit {
  width: fit-content;
  right: 20px;
}
.submit-wrapper {
  height: 130px;
  margin-top: 32px;
  .save-btn {
    width: 200px;
  }
  button:disabled {
    opacity: 0.5;
    color: white;
  }
}
.belong-dismiss {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  padding: 0px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  transition: all 0.5s;
  align-items: center;
  display: flex;
  border: none;
  top: -10px;
  right: -10px;
}
.heath-note-label {
  width: calc(50% / 4 - 25px);
}
.heath-note {
  width: calc(50% + 50% * 3 / 4 - 6px);
}

$employee-detail-width: 850px;
.employee-detail {
  max-width: $employee-detail-width;
  margin: auto;
  margin-top: 135px;
}

.rel-doc-contents {
  max-width: 850px;
  margin: auto;
}

.employee-detail table {
  table-layout: fixed;
}
