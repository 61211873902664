.login {
  width: 45rem;

  &__header {
    font-size: 4.8rem;
    font-weight: $font-weight-bold;
  }
  &__btn {
    &--submit {
      width: 23rem;
    }
  }
}
