.last-updated-date {
    &__title {
        color: #616161;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0em;
        line-height: 22px;
        text-align: left;
    }

    &__date {
        color: #212121;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0em;
        line-height: 22px;
        text-align: left;
    }
}

.related-docs-images__list__title {
    border-bottom: 1px solid #BDBDBD;
    font-size: 16px;
    font-weight: bold;
    margin-top: 16px;
    padding-bottom: 16px;
}

.related-docs-images__list__wrapper {
    border-bottom: 1px solid #BDBDBD;
    column-gap: 16px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 16px;
    padding-bottom: 16px;
    row-gap: 16px;
}

.related-docs-images__list__image {
    align-items: center;
    background-color: #F5F5F5;
    border: 1px solid #BDBDBD;
    cursor: pointer;
    display: flex;
    height: 256px;
    justify-content: center;

    > img {
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
}

.related-docs-images__list__action {
    align-items: center;
    display: flex;
    justify-content: space-between;

    > div:first-child {
        color: #DD5E56;
        cursor: pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0em;
        line-height: 22px;
        text-align: left;

        > img {
            margin-right: 8px;
        }
    }

    > div:last-child {
        color: #296661;
        cursor: pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0em;
        line-height: 22px;
        text-align: right;

        > img {
            margin-right: 8px;
        }
    }
}
